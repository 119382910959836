/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const asinIngest = /* GraphQL */ `
  query AsinIngest($email: String) {
    asinIngest(email: $email)
  }
`;
export const getStripeCustomer = /* GraphQL */ `
  query GetStripeCustomer($stripeCustomerId: String, $email: String) {
    getStripeCustomer(stripeCustomerId: $stripeCustomerId, email: $email)
  }
`;
export const getRefreshToken = /* GraphQL */ `
  query GetRefreshToken(
    $authCode: String
    $region: String
    $email: String
    $selling_partner_id: String
  ) {
    getRefreshToken(
      authCode: $authCode
      region: $region
      email: $email
      selling_partner_id: $selling_partner_id
    )
  }
`;
export const inventoryIngest = /* GraphQL */ `
  query InventoryIngest($email: String) {
    inventoryIngest(email: $email)
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      email
      username
      identityID
      firstName
      lastName
      hasRfToken
      stripeId
      customerIdentifier
      plan
      stripeSubsId
      paymentSource
      lastPaymentDate
      nextPaymentDate
      trialEndDate
      stripeActiveCheck
      STRDataStartDate
      STRDataEndDate
      Marketplace
      createdAt
      updatedAt
      owner
      refreshToken
      sellingPartnerIds
      sellerIdNA
      id
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        email
        username
        identityID
        firstName
        lastName
        hasRfToken
        stripeId
        plan
        stripeSubsId
        paymentSource
        lastPaymentDate
        nextPaymentDate
        trialEndDate
        stripeActiveCheck
        STRDataStartDate
        STRDataEndDate
        Marketplace
        createdAt
        updatedAt
        owner
        refreshToken
        sellingPartnerIds
        sellerIdNA
        id
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJob = /* GraphQL */ `
  query GetJob($id: ID!) {
    getJob(id: $id) {
      id
      userID
      createdAt
      user {
        email
        username
        identityID
        firstName
        lastName
        hasRfToken
        stripeId
        plan
        stripeSubsId
        paymentSource
        lastPaymentDate
        nextPaymentDate
        trialEndDate
        stripeActiveCheck
        STRDataStartDate
        STRDataEndDate
        Marketplace
        createdAt
        updatedAt
        owner
        refreshToken
        sellingPartnerIds
        sellerIdNA
        id
        __typename
      }
      ingestJobID
      ingestJob {
        id
        marketID
        createdAt
        startIngestDateTime
        endIngestDateTime
        lastIngestedOrderDateTime
        ingestIntervalDays
        status
        sellerId
        cognitoID
        variant
        asin
        updatedAt
        ingestJobJobId
        owner
        __typename
      }
      ingestJobStatus
      forecastJobStatus
      forecastJobID
      forecastJob {
        id
        createdAt
        status
        isDeleted
        startIngest
        endIngest
        ingestJobId
        market
        sellingPartnerId
        variant
        asin
        message
        updatedAt
        forecastJobJobId
        owner
        __typename
      }
      dashboardId
      bucket
      userIDCognito
      variant
      updatedAt
      jobUserId
      jobIngestJobId
      jobForecastJobId
      owner
      __typename
    }
  }
`;
export const listJobs = /* GraphQL */ `
  query ListJobs(
    $id: ID
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listJobs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userID
        createdAt
        ingestJobID
        ingestJobStatus
        forecastJobStatus
        forecastJobID
        dashboardId
        bucket
        userIDCognito
        variant
        updatedAt
        jobUserId
        jobIngestJobId
        jobForecastJobId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIngestJob = /* GraphQL */ `
  query GetIngestJob($id: ID!) {
    getIngestJob(id: $id) {
      id
      marketID
      createdAt
      startIngestDateTime
      endIngestDateTime
      lastIngestedOrderDateTime
      ingestIntervalDays
      resultFile {
        bucket
        region
        key
        __typename
      }
      status
      sellerId
      cognitoID
      job {
        id
        userID
        createdAt
        ingestJobID
        ingestJobStatus
        forecastJobStatus
        forecastJobID
        dashboardId
        bucket
        userIDCognito
        variant
        updatedAt
        jobUserId
        jobIngestJobId
        jobForecastJobId
        owner
        __typename
      }
      variant
      asin
      updatedAt
      ingestJobJobId
      owner
      __typename
    }
  }
`;
export const listIngestJobs = /* GraphQL */ `
  query ListIngestJobs(
    $id: ID
    $filter: ModelIngestJobFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIngestJobs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        marketID
        createdAt
        startIngestDateTime
        endIngestDateTime
        lastIngestedOrderDateTime
        ingestIntervalDays
        status
        sellerId
        cognitoID
        variant
        asin
        updatedAt
        ingestJobJobId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getForecastJob = /* GraphQL */ `
  query GetForecastJob($id: ID!) {
    getForecastJob(id: $id) {
      id
      forecastParam {
        JobRoot
        Domain
        TimeSeriesDataFrequency
        TimeSeriesTimestampFormat
        ForecastHorizon
        PerformAutoML
        DeleteOnCompletion
        PredAlgo
        holiday
        __typename
      }
      resultFile {
        bucket
        region
        key
        __typename
      }
      createdAt
      status
      isDeleted
      startIngest
      endIngest
      ingestJobId
      market
      sellingPartnerId
      job {
        id
        userID
        createdAt
        ingestJobID
        ingestJobStatus
        forecastJobStatus
        forecastJobID
        dashboardId
        bucket
        userIDCognito
        variant
        updatedAt
        jobUserId
        jobIngestJobId
        jobForecastJobId
        owner
        __typename
      }
      variant
      asin
      message
      updatedAt
      forecastJobJobId
      owner
      __typename
    }
  }
`;
export const listForecastJobs = /* GraphQL */ `
  query ListForecastJobs(
    $id: ID
    $filter: ModelForecastJobFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listForecastJobs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        status
        isDeleted
        startIngest
        endIngest
        ingestJobId
        market
        sellingPartnerId
        variant
        asin
        message
        updatedAt
        forecastJobJobId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersBySellerIdNA = /* GraphQL */ `
  query UsersBySellerIdNA(
    $sellerIdNA: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersBySellerIdNA(
      sellerIdNA: $sellerIdNA
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        username
        identityID
        firstName
        lastName
        hasRfToken
        stripeId
        plan
        stripeSubsId
        paymentSource
        lastPaymentDate
        nextPaymentDate
        trialEndDate
        stripeActiveCheck
        STRDataStartDate
        STRDataEndDate
        Marketplace
        createdAt
        updatedAt
        owner
        refreshToken
        sellingPartnerIds
        sellerIdNA
        id
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const jobsByDate = /* GraphQL */ `
  query JobsByDate(
    $userID: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByDate(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        createdAt
        ingestJobID
        ingestJobStatus
        forecastJobStatus
        forecastJobID
        dashboardId
        bucket
        userIDCognito
        variant
        updatedAt
        jobUserId
        jobIngestJobId
        jobForecastJobId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const jobsByForecast = /* GraphQL */ `
  query JobsByForecast(
    $forecastJobID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByForecast(
      forecastJobID: $forecastJobID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        createdAt
        ingestJobID
        ingestJobStatus
        forecastJobStatus
        forecastJobID
        dashboardId
        bucket
        userIDCognito
        variant
        updatedAt
        jobUserId
        jobIngestJobId
        jobForecastJobId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sellerIdByDate = /* GraphQL */ `
  query SellerIdByDate(
    $sellerId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIngestJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sellerIdByDate(
      sellerId: $sellerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        marketID
        createdAt
        startIngestDateTime
        endIngestDateTime
        lastIngestedOrderDateTime
        ingestIntervalDays
        status
        sellerId
        cognitoID
        variant
        asin
        updatedAt
        ingestJobJobId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const forecastsByDate = /* GraphQL */ `
  query ForecastsByDate(
    $isDeleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelForecastJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    forecastsByDate(
      isDeleted: $isDeleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        status
        isDeleted
        startIngest
        endIngest
        ingestJobId
        market
        sellingPartnerId
        variant
        asin
        message
        updatedAt
        forecastJobJobId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

import React from 'react'
import { useState,useEffect } from 'react';
import { Amplify, Auth, API,Storage, Cache  } from 'aws-amplify';
import MyIcon from '../ui-components/MyIcon';
import Logo from '../ui-components/Logo';
import { withAuthenticator, Flex } from '@aws-amplify/ui-react';
import { getUser } from '../graphql/queries';
// import { createForecastJob, createIngestJob, createJob } from '../graphql/mutations';
import {
  Button,
  Divider,
  Radio,
  SelectField,
  Text,
  TextField,
  Input,
  Label,
  Link
} from "@aws-amplify/ui-react";
import awsconfig from '../aws-exports';
const { DateTime } = require("luxon");

Amplify.configure(awsconfig);

const createJob = `
  mutation CreateJob(
    $input: CreateJobInput!
    $condition: ModelJobConditionInput
  ) {
    createJob(input: $input, condition: $condition) {
      id
      userID
      userIDCognito
      ingestJobID
      ingestJobStatus
      forecastJobID
      forecastJobStatus
      owner
      updatedAt
      createdAt
      variant
      __typename
    }
  }
`;

const createForecastJob = `
mutation CreateForecastJob(
    $input: CreateForecastJobInput!
    $condition: ModelForecastJobConditionInput
  ) {
    createForecastJob(input: $input, condition: $condition) {
      id
      forecastParam {
        JobRoot
        Domain
        TimeSeriesDataFrequency
        TimeSeriesTimestampFormat
        ForecastHorizon
        PerformAutoML
        DeleteOnCompletion
        PredAlgo
        holiday
        __typename
      }
      resultFile {
        bucket
        region
        key
        __typename
      }
      createdAt
      status
      isDeleted
      startIngest
      endIngest
      ingestJobId
      market
      sellingPartnerId
      updatedAt
      forecastJobJobId
      owner
      asin
      __typename
    }
  }`


const createIngestJob = `
  mutation CreateIngestJob(
    $input: CreateIngestJobInput!
    $condition: ModelIngestJobConditionInput
  ) {
    createIngestJob(input: $input, condition: $condition) {
      id
      marketID
      createdAt
      asin
      variant
      startIngestDateTime
      endIngestDateTime
      lastIngestedOrderDateTime
      ingestIntervalDays
      resultFile {
        bucket
        region
        key
        __typename
      }
      status
      sellerId
      cognitoID
      updatedAt
      ingestJobJobId
      owner
      __typename
    }
  }
`;


function CreateForecast({closeNewForecast, closeForecastRefresh}) {



  const [hasStartDateError, setHasStartDateError] = React.useState(false);
  const [hasASINError, setHasASINError] = React.useState(false);
  const [hasDaysError, setHasDaysError] = React.useState(false);
  const [hasAlgoError, setHasAlgoError] = React.useState(false);
  const [hasHolidayError, setHasHolidayError] = React.useState(false);
  const [hasAutoError, setHasAutoError] = React.useState(false);
  const [isAlgorithmFieldDisabled, setIsAlgorithmFieldDisabled] = React.useState(false);

  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [days, setDays] = React.useState("");
  const [holiday, setHoliday] = React.useState(false);
  const [auto, setAuto] = React.useState(false);
  const [algo, setAlgo] = React.useState("");
  const [metric, setMetric] = React.useState("REVENUE");
  const [asin, setASIN] = React.useState("");
  const [asinData, setASINData] = React.useState([]);
  const [isProcessingNewForecast, setIsProcessingNewForecast] = useState(false);
  const [isDownloadingASINData, setIsDownloadingASINData] = useState(false);
  const [asinEmptyError, setASINEmptyError] = useState("");

  const [sellerId, setSellerId] = React.useState("");

      const regionData = {

        'ATVPDKIKX0DER' : "NA",
        'A1AM78C64UM0Y8': "NA",
        'A2EUQ1WTGCTBG2': "NA",
        'A2Q3Y263D00KWC': "NA",

        'A1RKKUPIHCS9HS': "EU",
        'A1F83G8C2ARO7P': "EU",
        'A13V1IB3VIYZZH': "EU",
        'A1805IZSGTT6HS': "EU",
        'A1PA6795UKMFR9': "EU",
        'APJ6JRA9NG5V4': "EU",
        'A2NODRKZP88ZB9': "EU",
        'A1C3SOZRARQ6R3': "EU",
        'ARBP9OOSHTCHU': "EU" ,
        'A33AVAJ2PDY3EV': "EU",
        'A17E79C6D8DWNP': "EU",
        'A2VIGQ35RCS4UG': "EU",
        'A21TJRUUN4KGV': "EU" ,
        'AMEN7PMS3EDWL': "EU" ,
        'A28R8C7NBKEWEA': 'EU',
         'AE08WJ6YKNBMC': 'EU',

        'AA19VAU5U5O7RUS': "FE",
        'A39IBJ37TRP1C6': "FE",
        'A1VC38T7YXB528': "FE",

    }


    
    const overlayStyle = {
      display: isProcessingNewForecast ? 'flex' : 'none',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    };

    const modalStyle = {
      backgroundColor: '#fff',
      borderRadius: '10px',
      padding: '20px',
      width: '90%',
      maxWidth: '400px',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
      textAlign: 'center',
      zIndex:'300'
    };

    const loaderStyle = {
      width: '50px',
      height: '50px',
      border: '5px solid #f3f3f3',
      borderTop: '5px solid #3498db',
      borderRadius: '50%',
      animation: 'spin 1s linear infinite',
      margin: '0 auto 20px',
    };

    const messageStyle = {
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#333',
    };

    async function loadSellerId() {
      let cognitoUser = await Auth.currentAuthenticatedUser();
      const { attributes } = cognitoUser;

      const result = await API.graphql({ query: getUser , variables:{id:attributes.email}});
      const regionCode = regionData[Cache.getItem('marketCode')];
      const sellingIds = JSON.parse(result["data"].getUser.sellingPartnerIds);
      console.log(result["data"].getUser)

      setSellerId(sellingIds[regionCode])
    }

    useEffect(() => {
      loadSellerId();
    },[]);// <-- Have to pass in [] here or is called on every render

    function handleStartDateChange(e) {
      const date = e.target.value;
      setStartDate(date);
      if (date) {
        const start = DateTime.fromISO(date);
        const end = DateTime.now();
        const diffInDays = Math.floor(end.diff(start, 'days').days);

        // Set the forecast period to 1/4 of the historical data length
        const forecastPeriod = Math.max(28, Math.floor(diffInDays / 4));
        setDays(forecastPeriod.toString());
      } else {
        setDays("");
      }
    }

    function handleDaysChange(e) {
      setDays(e.target.value );
    }

    function handleHolidayChange(e) {
      if(e.target.value == "yes") {
        setHoliday(true);
      }
      else {
        setHoliday(false);
      }
    }

    function handleAutoChange(e) {
      if(e.target.value == "yes") {
        setAuto(true);
        setIsAlgorithmFieldDisabled(true);
        setAlgo("");
      }
      else {
        setAuto(false);
        setIsAlgorithmFieldDisabled(false);
      }
    }

    function handleAlgorithmChange(e) {
      setAlgo(e.target.value );
    }

    function handleMetricChange(e) {
      setMetric(e.target.value );

      if(e.target.value == "INVENTORY"){
        downloadData();
      }

    }

    async function downloadData() {
      try {
        setIsDownloadingASINData(true);
        const market = Cache.getItem('marketCode');
    
        // Fetch MFN Data
        let mfnURL = market + "_Inventory/MFN/ASIN_latest_data.json";
        let mfnInventoryData = [];
        try {
          const mfnResult = await Storage.vault.get(mfnURL, { download: true });
          mfnInventoryData = await new Response(mfnResult.Body).json();
        } catch (error) {
          console.log("MFN data not found or error occurred:", error);
          setIsDownloadingASINData(false);
        }
    
        // Fetch AFN Data
        let afnURL = market + "_Inventory/AFN/ASIN_latest_data.json";
        let afnInventoryData = [];
        try {
          const afnResult = await Storage.vault.get(afnURL, { download: true });
          afnInventoryData = await new Response(afnResult.Body).json();
        } catch (error) {
          console.log("AFN data not found or error occurred:", error);
          setIsDownloadingASINData(false);
        }
    
        // Process MFN Data
        const mfnData = Object.values(mfnInventoryData).map(item => ({
          asin: item.asin,
          sku: item.sellerSku,
          availableQuantity: item.totalQuantity
        }));
    
        // Process AFN Data
        const afnData = Object.values(afnInventoryData).map(item => ({
          asin: item.asin,
          sku: item.sellerSku,
          availableQuantity: item.totalQuantity
        }));
    
        // Combine MFN and AFN Data
        const combinedDataMap = {};
        [mfnData, afnData].forEach(dataSet => {
          dataSet.forEach(item => {
            if (combinedDataMap[item.asin]) {
              combinedDataMap[item.asin].availableQuantity += item.availableQuantity;
              combinedDataMap[item.asin].sku += `, ${item.sku}`;
            } else {
              combinedDataMap[item.asin] = { ...item };
            }
          });
        });
    
        const combinedData = Object.values(combinedDataMap)
          .filter(item => item.availableQuantity > 0);

        if(combinedData.length == 0){
          setASINEmptyError("You don't have any ASIN data for this market as of the moment.");
        }
    
        setASINData(combinedData);

        setIsDownloadingASINData(false);
      } catch (exception) {
        console.log(exception);
        setIsDownloadingASINData(false);
      }
    }

    function handleASINChange(e) {
      setASIN(e.target.value );
    }

    async function createForecast() {
      setIsProcessingNewForecast(true);
      let user = await Auth.currentAuthenticatedUser()

      if(startDate == ""){
        setHasStartDateError(true);
        setIsProcessingNewForecast(false);
        return;
      }
      else{
        setHasStartDateError(false);
      }

      if (days == "" ){
        setIsProcessingNewForecast(false);
        setHasDaysError(true);
        return;
      }
      else {
        setHasDaysError(false);
      }

      if(!holiday){
        setIsProcessingNewForecast(false);
        setHasHolidayError(true)
        return;
      }
      else {
        setHasHolidayError(false)
      }

      // if(!auto){
      //   setHasAutoError(true)
      //   return;
      // }
      // else {
      //   setHasAutoError(false)
      // }

      if(algo == "" && !auto){
        setIsProcessingNewForecast(false);
        setHasAlgoError(true)
        return;
      }
      else {
        setHasAlgoError(false)
      }


      if(metric == "INVENTORY" && asin == ""){
        setIsProcessingNewForecast(false);
        setHasASINError(true);
        return;
      }else{
        setHasASINError(false);
      }

      const marketId = Cache.getItem('marketCode');
      const start = DateTime.fromISO(startDate);

      const end = DateTime.now();

      const diffInDays = end.diff(start, 'days');
      console.log("diff is ",diffInDays.days)

      const credentials = await Auth.currentUserCredentials();

      let ingestJobPayload = {
          startIngestDateTime: start.toISO(),
          endIngestDateTime: end.toISO(),
          lastIngestedOrderDateTime: end.toISO(),
          ingestIntervalDays: Math.floor(diffInDays.days),
          marketID: marketId,
          sellerId: sellerId,
          cognitoID: credentials.identityId

      };

      //INGEST JOB PAYLOAD REQUIRED DATA FOR INVENTORY
      if(metric == "INVENTORY"){
        ingestJobPayload = {
            asin: asin,
            cognitoID: credentials.identityId,
            endIngestDateTime: end.toISO(),
            startIngestDateTime: start.toISO(),
            ingestIntervalDays: Math.floor(diffInDays.days),
            lastIngestedOrderDateTime: end.toISO(),
            marketID: marketId,
            sellerId: sellerId,
            variant: metric
        };
      }

      console.log(ingestJobPayload);

      const forecastHorizon = days > 12 ? 12 : days < 1 ? 1 : days;
      const performAutoML = auto;
      let predAlgo = algo;
      if(!auto)
        predAlgo = "";

      let hol = false;
      if(holiday){
        hol = Cache.getItem('marketCountryCode')
      }



      const ingestResponse = (await API.graphql({query: createIngestJob,variables:{input:ingestJobPayload}})) as{data:{
          createIngestJob: {
              id:string
          }
      }};

      const forecastJobPayload = {
        startIngest: start.toISO(),
        endIngest: end.toISO(),
        ingestJobId: ingestResponse.data.createIngestJob.id,
        market: marketId,
        sellingPartnerId:sellerId,
        isDeleted:"false",
        forecastParam: {
          JobRoot: 'af',
          Domain: 'METRICS',
          ForecastHorizon: forecastHorizon,
                  TimeSeriesDataFrequency: 'D',
          TimeSeriesSchema: {
                      Attributes: [
              {
                  AttributeName: 'metric_name',
                  AttributeType: 'string'
              },
              {
                  AttributeName: 'timestamp',
                  AttributeType: 'timestamp'
              },
              {
                  AttributeName: 'metric_value',
                  AttributeType: 'float'
              }
                      ]
                  },
          TimeSeriesTimestampFormat: 'yyyy-MM-dd',
          PerformAutoML: performAutoML,
          PredAlgo : predAlgo,
          holiday: hol,
          FeaturizationConfig: {
            ForecastFrequency: 'D',
            Featurizations: [
              {
                AttributeName: 'metric_value',
                FeaturizationPipeline: [
                  {
                    FeaturizationMethodName: 'filling',
                    FeaturizationMethodParameters: {
                      aggregation: 'sum',
                      backfill: 'zero',
                      frontfill: 'none',
                      middlefill: 'zero'
                    }
                  }
                ]
              }
            ]
          },
          DeleteOnCompletion: false
        },
        variant: metric
      };

      if(metric != "REVENUE"){
        forecastJobPayload["asin"] = asin;
      }

      console.log(forecastJobPayload)
      const forecastResponse = (await API.graphql({query: createForecastJob,variables:{input:forecastJobPayload}})) as{data: {
          createForecastJob: {
              id:string
          }
      }};
      console.log("ingest",ingestResponse);
      console.log("forecastResponse",forecastResponse);
      const jobInput = {
          ingestJobID: ingestResponse.data.createIngestJob.id,
          forecastJobID: forecastResponse.data.createForecastJob.id,
          userID: user.attributes.email,
          userIDCognito: credentials.identityId,
          variant: metric
      };

      try{
        const createJobResponse = await API.graphql({query: createJob,variables:{input:jobInput}});
        setIsProcessingNewForecast(false);
        closeForecastRefresh();
        console.log("createJobResponse",createJobResponse)
      }catch(e){
        setIsProcessingNewForecast(false);
        console.log("error creating a job: ",e)
      }
      
      console.log("createJob input: ", jobInput)
      

  }
    return (
      <Flex
        gap="16px"
        direction="column"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        position="fixed"
        top="0"
        left="0"
        padding="0px"
        backgroundColor="rgba(0, 0, 0, 0.5)"
      >



      <Flex
      gap="16px"
      direction="column"
      width="899px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"

    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 24px 24px 24px"

      >
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="space-between"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"

        >


        </Flex>
        <Flex
          gap="24px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"

        > <img src="horizontal-aitium-logo.png" width="25%"/>
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="space-between"
            alignItems="center"
            shrink="0"
            // alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"

          >
            <Text
              fontFamily="Inter"
              fontSize="24px"
              fontWeight="600"
              color="rgba(13,26,38,1)"
              lineHeight="30px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Launch New Forecast Job"

            ></Text>
          </Flex>
          <Text
            fontFamily="Inter"
            fontSize="12px"
            fontWeight="400"
            color="rgba(48,64,80,1)"
            lineHeight="18px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="A forecast model trained with machine learning can take - dependent on sales history &#xA;- up to half a day to build (although the visualization is ready almost immediately afterwards!)"

          ></Text>
        </Flex>
        <Flex
          gap="32px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"

        >
          <Flex
            gap="40px"
            direction="column"
            width="100px"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"

          >
            <SelectField
              width="unset"
              height="unset"
              label="Metric"
              shrink="0"
              alignSelf="stretch"
              size="default"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              value={metric}
              onChange={handleMetricChange}

            >
              <option value="REVENUE">Revenue</option>
              <option value="INVENTORY">Inventory</option> 
            </SelectField>

           { metric =="INVENTORY" && (
            <SelectField
                width="unset"
                height="unset"
                label="ASIN"
                shrink="0"
                alignSelf="stretch"
                size="default"
                isDisabled={false}
                labelHidden={false}
                hasError={hasASINError}
                variation="default"
                value={asin}
                onChange={handleASINChange}

                >
                <option value="">Choose from the available ASINS</option>
                {asinData.map((item, index) => (
                  <option key={index} value={item.asin}>
                    {item.asin}
                  </option>
                ))}
            </SelectField>) }

            <Flex
              gap="10px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"

            >

            <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(48,64,80,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="510px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Ingest Start Date"

          ></Text>

          
          <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="400"
          color="rgba(48,64,80,1)"
          lineHeight="21px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Minimum 90 days of sales history"

        ></Text>



            <TextField
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                hasError={hasStartDateError}
              />

            </Flex>
            <Flex
              gap="8px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"

            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(48,64,80,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="510px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Forecast Period"

              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="400"
                color="rgba(48,64,80,1)"
                lineHeight="21px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Forecast horizons must be at least 1/4 of the historical period."

              ></Text>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"

              >


  <Input
    id="day"
    width="60%"
    type="number"
    hasError={hasDaysError}
    onChange={handleDaysChange}
    value={days}
  /><Label htmlFor="days">days </Label>
              </Flex>
            </Flex>
          </Flex>
          <Divider
            // width="36px"
            height="unset"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="vertical"

          ></Divider>
          <Flex
            gap="40px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"

          >
            <Flex
              gap="8px"
              direction="column"
              width="unset"
              height="72px"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"

            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(48,64,80,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Include National Holidays?"

              ></Text>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"

              >
                <Radio
                  id="holiday"
                  name="holiday"
                  width="unset"
                  height="unset"
                  shrink="0"
                  size="default"
                  hasError={hasHolidayError}
                  isDisabled={false}
                  labelPosition="end"
                  children="Yes"
                  value="yes"
                  onChange={handleHolidayChange}
                ></Radio>
                <Radio
                  id="holiday"
                  name="holiday"
                  width="unset"
                  height="unset"
                  shrink="0"
                  size="default"
                  hasError={hasHolidayError}
                  isDisabled={false}
                  labelPosition="end"
                  children="No"
                  value="no"
                  onChange={handleHolidayChange}
                ></Radio>
              </Flex>
            </Flex>
            <Flex
              gap="8px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"

            >
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(48,64,80,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="AutoML"

              ></Text>
              <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"

              >
                <Radio
                  id="auto"
                  name="auto"
                  value="yes"
                  width="unset"
                  height="unset"
                  shrink="0"
                  size="default"
                  hasError={hasAutoError}
                  isDisabled={false}
                  labelPosition="end"
                  children="Yes"
                  onChange={handleAutoChange}
                ></Radio>
                <Radio
                  id="auto"
                  name="auto"
                  value="no"
                  width="unset"
                  height="unset"
                  shrink="0"
                  size="default"
                  hasError={hasAutoError}
                  isDisabled={false}
                  labelPosition="end"
                  children="No"
                  onChange={handleAutoChange}
                ></Radio>
              </Flex>
              <Link href="https://gaiiff.com/machine-learning/#amazon-forecast-models" target="_blank">
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="700"
                color="rgba(0,96,122,1)"
                lineHeight="21px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="More Information"

              ></Text></Link>
            </Flex>
            
            <SelectField
              width="unset"
              height="unset"
              label="Algorithm"
              placeholder=" "
              shrink="0"
              alignSelf="stretch"
              size="default"
              isDisabled={isAlgorithmFieldDisabled}
              labelHidden={false}
              variation="default"
              value={algo}
              onChange={handleAlgorithmChange}
              hasError={hasAlgoError}
              defaultValue=""
            >

                    <option value="deepar">DeepAR+ (minimum 300 days' history)</option>
                    <option value="cnnqr">CNN-QR</option>
                    <option value="prophet">Prophet</option>
                    <option value="npts">NPTS</option>
                    <option value="arima">Arima</option>
                    <option value="ets">ETS</option>
            </SelectField>


             

          </Flex>
          
        </Flex>
      </Flex>
      <Flex
        gap="12px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-end"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        border="1px SOLID rgba(220,222,224,1)"
        padding="15px 15px 15px 15px"

      >
        <Flex
          gap="12px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="space-between"
          alignItems="flex-start"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"

        >
          <Button
            width="unset"
            height="unset"
            shrink="0"
            backgroundColor="rgba(255,255,255,1)"
            size="small"
            isDisabled={false}
            variation="default"
            children="Close"
            onClick={closeNewForecast}
          ></Button>
          <Button
            width="unset"
            height="unset"
            shrink="0"
            size="small"
            isDisabled={false}
            variation="primary"
            children="Submit"
            // type="submit"
            onClick={createForecast}
          ></Button>
        </Flex>
      </Flex>
    </Flex>
    
    {isProcessingNewForecast && (<div style={overlayStyle}>
      <div style={modalStyle}>
        <div style={loaderStyle}></div>
        <div style={messageStyle}>
          We are creating your forecast request. Please wait...
        </div>
      </div>
    </div>)}


    {isDownloadingASINData && (<div style={overlayStyle}>
      <div style={modalStyle}>
        <div style={loaderStyle}></div>
        <div style={messageStyle}>
          We are currently fetching your ASIN data. Please wait...
        </div>
      </div>
    </div>)}


    {asinEmptyError != "" && (
      <div style={errorModalStyles.modalOverlay}>
          <div style={errorModalStyles.modalContent}>
            <h2 style={errorModalStyles.modalHeader}>Error</h2>
            <p style={errorModalStyles.modalBody}>
              {asinEmptyError}
            </p>
            <button onClick={closeErrorModal} style={errorModalStyles.modalButton}>
              OK
            </button>
          </div>
        </div>
    )}
    </Flex>

    )

}


const errorModalStyles = {
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#0073e6",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    width: "400px",
    textAlign: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
  modalHeader: {
    margin: "0 0 10px",
    fontSize: "20px",
  },
  modalBody: {
    fontSize: "16px",
    marginBottom: "20px",
  },
  modalButton: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#0073e6",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default withAuthenticator(CreateForecast);




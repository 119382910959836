import {Amplify, API, Auth, Cache} from 'aws-amplify';
import { withAuthenticator,Flex,Text } from '@aws-amplify/ui-react';
import React,{useEffect, useState} from 'react';
import awsconfig from './../aws-exports';
import AccountRegionCard from '../ui-components/AccountRegionCard';
import {Hero} from "../ui-components";
import { getUser, getStripeCustomer } from '../graphql/queries';
import { updateUser } from '../graphql/mutations';
import  { useNavigate } from 'react-router'
import GeneralFooter from '../ui-components/GeneralFooter';
import DialogModal from '../components/dialog-modal.tsx'; 
Amplify.configure(awsconfig);

const pricingTableId = process.env.REACT_APP_PRICING_TABLE_ID;
const publishableKey = process.env.REACT_APP_PUBLISHABLE_KEY;

function Products({ signOut, user }) {

  const navigate = useNavigate();
  const [dialogModalOpen, setDialogModalOpen] = useState(false);
  const [dialogModalMessage, setDialogModalMessage] = useState('');
  const [isProcessingCheckout, setIsProcessingCheckout] = useState(false);

  const handleCloseModal = () => {
    setDialogModalOpen(false); 
  };

  const overlayStyle = {
    display: isProcessingCheckout ? 'flex' : 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalStyle = {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '20px',
    width: '90%',
    maxWidth: '400px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
    textAlign: 'center',
  };

  const loaderStyle = {
    width: '50px',
    height: '50px',
    border: '5px solid #f3f3f3',
    borderTop: '5px solid #3498db',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
    margin: '0 auto 20px',
  };

  const messageStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#333',
  };

  const [isAWSSubscriptionInfoOpen, setIsAWSSubscriptionInfoOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleAWSSubscription = () => {
    setIsAWSSubscriptionInfoOpen(true);
    window.open(
      "https://aws.amazon.com/marketplace/pp/prodview-epowjzdmfmli6",
      "_blank"
    );
  };

  const closeAWSInfoModal = () => {
    setIsAWSSubscriptionInfoOpen(false);
  };

  
  const closeErrorModal = () => {
    setHasError(false);
  };



  function StripePriceTable(){
    
    const[isLoading, setIsLoading] = useState(true);
    const[identityID, setIdentityID] = useState("");
    const[customerEmail, setCustomerEmail] = useState("");

    async function checkAccountPricing(){
      try{
        setIsLoading(true)
        let cognitoUser = await Auth.currentAuthenticatedUser();
        
        const { attributes } = cognitoUser;
        const credentials = await Auth.currentUserCredentials();
        const identityArray = credentials.identityId.split(":");
        let identityId = identityArray[1];  
        
        setCustomerEmail(cognitoUser.attributes.email)  
        setIdentityID(identityId);
        const search = window.location.search;
        const params = new URLSearchParams(search);

        const checkout = params.get('checkout');
        
        if(checkout && checkout.length > 0){
          setIsLoading(true);  
        }
        else {
          setIsLoading(false); 
        }
      }catch(error){
        console.log(error)
      }
    }
    useEffect(() => {
      checkAccountPricing();
      const script = document.createElement("script");
      script.src = "https://js.stripe.com/v3/pricing-table.js";
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }, []);
  
    if(isLoading){
      return null;
    }

    return (
      <div  style={{border: "1px solid rgba(200,200,200,0.5)", borderRadius: "8px", padding: "16px", backgroundColor: "rgba(255, 255, 255, 1)",}}>
        { React.createElement("stripe-pricing-table", {"pricing-table-id": pricingTableId,"publishable-key": publishableKey,"client-reference-id":identityID,"customer-email":customerEmail })}
      </div>
    );
  }  
    
  useEffect(() => {
    checkAccount();
    processAWSFulfillmentCallback();
    
  }, []); 

  async function checkAuthorization() {
    if(Cache.getItem('user')){
      const refreshToken = Cache.getItem('user')?.refreshToken;

      if (!refreshToken) {
        setIsProcessingCheckout(false);
        setDialogModalMessage("Aitium requires authorization on Amazon Seller Central. Redirecting to authorization page...");
        setDialogModalOpen(true);
        navigate('/authorize');
        return false;
      }else{
        setIsProcessingCheckout(false);
        navigate('/sales-report');
      }
      return true;
    }else{
      setIsProcessingCheckout(false);
    }

    return false;
  }

  async function processAWSFulfillmentCallback(){
    try{
      let cognitoUser = await Auth.currentAuthenticatedUser();
      const { attributes } = cognitoUser;

      const search = window.location.search;
      const params = new URLSearchParams(search);

        // Extract query parameters
      const registrationToken = params.get('x-amzn-marketplace-token');

      if ((registrationToken?.length ?? 0) > 0) {
        setIsProcessingCheckout(true);

        const response = await fetch('https://sv4u3qx3wba5q7wua367i5skqu0hikci.lambda-url.us-east-1.on.aws', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
              {
                'x-amzn-marketplace-token': registrationToken,
                'email': cognitoUser.attributes.email,
            })
        });

        const responseData = await response.json()
        console.log(responseData);

        if (response.ok) {
            console.log('Success:', response);
            checkAuthorization();
        } else {
            setHasError(true);
            setErrorMessage(responseData.message.toString());
            setIsProcessingCheckout(false);
        }

      }

    }catch(error){
      setHasError(true);
      setErrorMessage(error?.toString() ?? "");
      console.log(error);
      setIsProcessingCheckout(false);
    }
  }

  async function checkAccount(){
    try{
      let cognitoUser = await Auth.currentAuthenticatedUser();
      const { attributes } = cognitoUser;

      // If redirecting back from Stripe
      const search = window.location.search;
      const params = new URLSearchParams(search);
      console.log(params)
      const checkout = params.get('checkout');
      console.log(checkout)
      if(checkout && checkout.length > 0){
        setIsProcessingCheckout(true);
        const response = await API.graphql({
          query: getStripeCustomer
          ,variables : {"checkout": checkout}
        });
        let created = 0;
        let customerId = "";
        let plan = "";
        const stripeData = JSON.parse(response["data"]["getStripeCustomer"]);
        
        // get the newest subscription of this customer checkout session as it's the one they just completed
        console.log(stripeData);
        for(let i = 0; i < stripeData['body']['data'].length; i++){
          if(stripeData['body']['data'][i]['created'] > created){
            created = stripeData['body']['data'][i]['created'];
            customerId = stripeData['body']['data'][i]['customer']
            plan = stripeData['body']['data'][i]['plan']['product']
          }
        }
        console.log("customerId, updated user",customerId);
        console.log("id, updated user",cognitoUser.attributes.email);

        await API.graphql({ query: updateUser , variables:{input:{id:cognitoUser.attributes.email, stripeId:customerId }}});
        const result = await API.graphql({ query: getUser , variables:{id:cognitoUser.attributes.email}});
        if(result['data'].getUser){
          result['data'].getUser['plan'] = plan;
          Cache.setItem('user', result['data'].getUser);
        }
        console.log('db is ', result['data'].getUser["refreshToken"])
        checkAuthorization();
      }
    }catch(error){
      console.error(error);
      setIsProcessingCheckout(false);
    }
    
    // const result = await API.graphql({ query: getUser , variables:{id:cognitoUser.attributes.email}});
    // if(result['data'].getUser == null || )
    // const stripeId  = result['data'].getUser.stripeId;
    // //user exists so forward to products page
    // if(stripeId && stripeId.length > 1){
    //   navigate("/authorize");
    // }

  }


  return (
    <div style={{width:"100%"}} >
    <Flex
      gap="24px"
      direction="column"
      width="100%"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="48px 48px 0px 48px"
      backgroundColor="rgba(250,250,250,1)"
      
    >
      <Flex
        gap="12px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        
      >
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="600"
          color="rgba(0,96,122,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Pricing"
          
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="600"
          color="rgba(13,26,38,1)"
          lineHeight="32px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Choose your plan"
          
        ></Text>
       
        
      </Flex>
     
      <p/>
      
      
      </Flex>


        <Flex
          gap="16px"
          direction="column"
          width="100%"
          justifyContent="center"
          alignItems="center"
          padding="16px"
          border="1px solid rgba(200,200,200,0.5)"
          borderRadius="8px"
          backgroundColor="rgba(255,255,255,1)"
        >
          <img
            src="/AWS-Marketplace_logo.svg"
            alt="Available on AWS Marketplace"
            style={{ width: "400px", height: "auto" }}
          />
          <button
            style={{
              backgroundColor: "rgba(0,96,122,1)",
              color: "white",
              padding: "10px 20px",
              borderRadius: "4px",
              border: "none",
              cursor: "pointer",
              fontSize: "18px",
              marginBottom: "30px"
            }}
            onClick={handleAWSSubscription}
          >
            Subscribe to AWS Marketplace
          </button>
        </Flex>
        <br/><br/>

        <Flex
          justifyContent="center"
          alignItems="center"
          width="100%"
          margin="16px 0"
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="500"
            color="rgba(128,128,128,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            children="OR"
          ></Text>
        </Flex>

        <br/><br/>

 
        <StripePriceTable/>
 
      
      <GeneralFooter/>

      <DialogModal open={dialogModalOpen} message={dialogModalMessage} onClose={handleCloseModal} />


      {isProcessingCheckout && (<div style={overlayStyle}>
        <div style={modalStyle}>
          <div style={loaderStyle}></div>
          <div style={messageStyle}>
            Please wait while we process your subscription.
          </div>
        </div>
      </div>)}


      {isAWSSubscriptionInfoOpen && (
        <div style={awsModalInfoStyles.modalOverlay}>
          <div style={awsModalInfoStyles.modalContent}>
            <h2 style={awsModalInfoStyles.modalHeader}>Subscription Pending</h2>
            <p style={awsModalInfoStyles.modalBody}>
              Please wait for a few minutes for the subscription to reflect on
              your account after successfully subscribing to the plan.
            </p>
            <button onClick={closeAWSInfoModal} style={awsModalInfoStyles.modalButton}>
              OK
            </button>
          </div>
        </div>
      )}


      {hasError && (
        <div style={awsModalInfoStyles.modalOverlay}>
          <div style={awsModalInfoStyles.modalContent}>
            <h2 style={awsModalInfoStyles.modalHeader}>Error</h2>
            <p style={awsModalInfoStyles.modalBody}>
              {errorMessage}
            </p>
            <button onClick={closeErrorModal} style={awsModalInfoStyles.modalButton}>
              OK
            </button>
          </div>
        </div>
      )}
      
    </div>
      
  );
}


const awsModalInfoStyles = {
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#0073e6",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    width: "400px",
    textAlign: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
  modalHeader: {
    margin: "0 0 10px",
    fontSize: "20px",
  },
  modalBody: {
    fontSize: "16px",
    marginBottom: "20px",
  },
  modalButton: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#0073e6",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default withAuthenticator(Products);

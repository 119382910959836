/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React, { useEffect } from "react";
import { getOverrideProps } from "./utils";
import { Flex, Text, Image } from "@aws-amplify/ui-react";

export default function CustomContactSection(props) {
  const { overrides, ...rest } = props;

  const contactSection = {
    container: {
      display: "flex",
      justifyContent: "space-between",
      fontFamily: "Arial, sans-serif",
      padding: "40px",
      marginTop: "20px",
      maxWidth: "100%",
      margin: "auto",
    },
    infoContainer: {
      flex: "1",
      marginLeft: "10%",
      marginRight: "50px",
      display: "block",
    },
    formContainer: {
      flex: "1",
      width: "75%", // Ensures 75% the screen width
      margin: "auto",
      backgroundColor: "#f9f9f9",
      borderRadius: "8px",
    },
    contactMethod: {
      marginBottom: "50px",
      display: "flex",
      alignItems: "center",
    },
    contactIcon: {
      marginRight: "10px",
    },
    contactText: {
      marginTop: "10px",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    link: {
      color: "#00607A",
      textDecoration: "none",
    },
  };

  useEffect(() => {
    // Create the script element
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    script.async = true;

    // Initialize the form once the script is loaded
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "7391276",
          formId: "1bf18f1b-4f81-4227-85f5-a0536525fdc4",
          target: "#hubspotForm",
        });
      }
    };

    // Append the script to the body
    document.body.appendChild(script);
  }, []);

  return (
    <Flex style={contactSection.container} {...getOverrideProps(overrides, "ContactSection")} {...rest}>
      <Flex style={contactSection.infoContainer} {...getOverrideProps(overrides, "InfoContainer")}>
        <Flex style={contactSection.contactMethod} {...getOverrideProps(overrides, "ContactMethodEmail")}>
          <Image
            src="email-icon.png"
            alt="email icon"
            style={contactSection.contactIcon}
            {...getOverrideProps(overrides, "ContactIconEmail")}
          />
          <Flex style={contactSection.contactText}>
            <Text fontWeight="500" fontSize="1.3em" {...getOverrideProps(overrides, "ContactMethodTitleEmail")}>
              By email
            </Text>
            <Text as="a" href="mailto:aitium@gaiiff.com" style={contactSection.link} {...getOverrideProps(overrides, "ContactMethodEmail")}>
              aitium@gaiiff.com
            </Text>
          </Flex>
        </Flex>

        <Flex style={contactSection.contactMethod} {...getOverrideProps(overrides, "ContactMethodPhone")}>
          <Image
            src="phone-icon.png"
            alt="phone icon"
            style={contactSection.contactIcon}
            {...getOverrideProps(overrides, "ContactIconPhone")}
          />
          <Flex direction="column" style={contactSection.contactText}>
            <Text fontWeight="500" fontSize="1.3em" {...getOverrideProps(overrides, "ContactMethodTitlePhone")}>
              By phone
            </Text>
            <Text as="a" href="tel:+11234567890" style={contactSection.link} {...getOverrideProps(overrides, "ContactMethodPhone")}>
              +1 571 200 5466
            </Text>
          </Flex>
        </Flex>

        <Flex style={contactSection.contactMethod} {...getOverrideProps(overrides, "ContactMethodLocation")}>
          <Image
            src="location-icon.png"
            alt="location icon"
            style={contactSection.contactIcon}
            {...getOverrideProps(overrides, "ContactIconLocation")}
          />
          <Flex direction="column" style={contactSection.contactText}>
            <Text fontWeight="500" fontSize="1.3em" {...getOverrideProps(overrides, "ContactMethodTitleLocation")}>
              In person
            </Text>
            <Text color="#5C6670" {...getOverrideProps(overrides, "ContactMethodLocation1")}>
              1350 Avenue of the Americas
            </Text>
            <Text color="#5C6670" {...getOverrideProps(overrides, "ContactMethodLocation2")}>
              Fl 2 -1040
            </Text>
            <Text color="#5C6670" {...getOverrideProps(overrides, "ContactMethodLocation3")}>
              New York NY 10019
            </Text>
            <Text color="#5C6670" {...getOverrideProps(overrides, "ContactMethodLocation4")}>
              United States
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex style={contactSection.formContainer} {...getOverrideProps(overrides, "FormContainer")}>
        <div id="hubspotForm"></div>
      </Flex>
    </Flex>
  );
}